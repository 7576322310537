import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { postsWorkersApiUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import "./CreatePosts.css"

type CreatePostsInput = {
  title: string;
  content: string;
  username: string;
};

const CreatePostsSchema = yup
  .object()
  .shape({
    title: yup.string().required(),
    content: yup.string().required(),
    username: yup.string().required(),
  })
  .required();

function CreatePosts() {
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePostsInput>({
    resolver: yupResolver(CreatePostsSchema),
  });

  const onSubmit = async (data: {
    username: string;
    title: string;
    content: string;
  }) => {
    const response = await axios.post(postsWorkersApiUrl + "/posts", data);
    if (response.status === 201) {
      navigate("/");
    } else {
      alert("error");
    }
  };

  return (
    <div className={"m-2 sm:m-4"}>
      <h1 className={"text-2xl font-bold"}>Create a post</h1>

      <div className="mt-2 p-2 bg-gray-200 rounded-lg">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"form-component"}>
            <label htmlFor="title">Title</label>
            <br />
            <input
              placeholder="Think of an interesting title"
              {...register("title")}
            />
            {errors.title?.message && (
              <p className={"errors-text"}>{errors.title?.message}</p>
            )}
          </div>

          <div className={"form-component"}>
            <label htmlFor="content">Content</label>
            <br />
            <textarea
              placeholder="Write your post here"
              {...register("content")}
            />
            {errors.content?.message && (
              <p className={"errors-text"}>{errors.content?.message}</p>
            )}
          </div>

          <div className={"form-component"}>
            <label htmlFor="username">Username</label>
            <br />
            <input
              placeholder="Enter your username"
              {...register("username")}
            />
            {errors.username?.message && (
              <p className={"errors-text"}>{errors.username?.message}</p>
            )}
          </div>

          <div style={{ color: "red" }}>
            {Object.keys(errors).length > 0 &&
              "There are errors, check your console."}
          </div>
          <button
            className={"submit-button"}
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreatePosts;
