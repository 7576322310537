import { Link } from "react-router-dom";
import React from "react";

export default function NavBar() {
  return (
    <div className={"bg-gray-300 p-4"}>
      <h1 className={"text-6xl font-bold font-montserrat"}>The KV Post</h1>
      <p className={"text-xs italic"}>
        Your source of the latest news in the KV world
      </p>
      <nav className={"mt-2"}>
        <Link
          className={"bg-gray-800 text-white hover:bg-gray-500 rounded-lg p-1"}
          to="/"
        >
          View all posts
        </Link>
        <Link
          className={
            "bg-gray-800 text-white hover:bg-gray-500 rounded-lg p-1 ml-1"
          }
          to="/create-posts"
        >
          Create a post
        </Link>
      </nav>
    </div>
  );
}
