import React, { useEffect } from "react";
import "./App.css";
import axios from "axios";
import { PostTemplate } from "./PostTemplate";
import { postsWorkersApiUrl } from "./config";

const postsStore: PostTemplate[] = [];

function App() {
  const [posts, setPosts]: [PostTemplate[], (posts: PostTemplate[]) => void] =
    React.useState(postsStore);
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState<boolean>(true);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  const [visiblePosts, setVisiblePosts]: [
    PostTemplate[],
    (posts: PostTemplate[]) => void
  ] = React.useState(postsStore);
  const [sortedByTime, setSortedByTime]: [
    boolean,
    (sortedByTime: boolean) => void
  ] = React.useState<boolean>(true);

  function sortPostsByTime(reverse: boolean) {
    let temporaryPosts: PostTemplate[];
    if (!reverse) {
      temporaryPosts = [...posts.sort(comparePostByCreatedTime)];
      setSortedByTime(false);
    } else {
      temporaryPosts = [...posts.sort(comparePostByCreatedTime).reverse()];
      setSortedByTime(true);
    }
    setVisiblePosts(temporaryPosts);
  }

  useEffect(() => {
    axios
      .get<PostTemplate[]>(postsWorkersApiUrl + "/posts")
      .then((response) => {
        setPosts(response.data);
        sortPostsByTime(true);
        setLoading(false);
      })
      .catch((ex) => {
        setError(JSON.stringify(ex))
        setLoading(false);
      });
  }, [loading]);

  return (
    <div className="App">
      {!loading ? (
        <div className={"posts"}>
          <div className={"m-2 sm:m-4"}>
            Sort posts:
            <button
              className={`m-2 p-1 bg-gray-300 hover:bg-gray-500 rounded-lg ${
                sortedByTime ? "bg-green-300" : "bg-gray-300"
              }`}
              onClick={() => sortPostsByTime(true)}
            >
              Latest posts first
            </button>
            <button
              className={`m-2 p-1 bg-gray-300 hover:bg-gray-500 rounded-lg ${
                !sortedByTime ? "bg-green-300" : "bg-gray-300"
              }`}
              onClick={() => sortPostsByTime(false)}
            >
              Earliest posts first
            </button>
          </div>
          {visiblePosts.map((post) => (
            <div
              key={post.created_at}
              className={"bg-gray-200 rounded-lg m-2 sm:m-4 p-2"}
            >
              <h3 className={"text-left text-3xl font-bold font-montserrat"}>
                {post.title}
              </h3>
              <p className={"text-sm text-left"}>
                by {post.username} at{" "}
                {new Date(post.created_at).toLocaleString()}
              </p>
              <p className={"text-left"}>{post.content}</p>
            </div>
          ))}
        </div>
      ) : (
        <div>Content is loading</div>
      )}
      {error && <p className="error">{error}</p>}
    </div>
  );
}

function comparePostByCreatedTime(postA: PostTemplate, postB: PostTemplate) {
  return postA.created_at < postB.created_at
    ? -1
    : postA.created_at > postB.created_at
    ? 1
    : 0;
}

export default App;
